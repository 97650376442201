define("bottomline-execution/components/shift-list", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service", "bottomline-execution/mixins/ask-odometer"], function (_exports, _computed, _component, _object, _service, _askOdometer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_askOdometer.default, {
    i18n: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    store: (0, _service.inject)(),
    appState: (0, _computed.alias)('shared.appState'),
    askForOdometer: (0, _computed.alias)('appState.truckSettings.odometer_on_shift_level'),
    router: (0, _service.inject)(),
    orderedShifts: (0, _computed.alias)('shifts'),
    sortedShifts: (0, _object.computed)('sortedFinishedShifts', 'sortedUnfinishedShifts', function () {
      return this.get('sortedFinishedShifts').concat(this.get('sortedUnfinishedShifts'));
    }),
    sortedFinishedShifts: (0, _computed.sort)('finishedShifts', 'finishedShiftsSortOrder'),
    sortedUnfinishedShifts: (0, _computed.sort)('unfinishedShifts', 'unfinishedShiftsSortOrder'),
    finishedShifts: (0, _computed.filterBy)('shifts', 'ConfirmedStartDT'),
    unfinishedShifts: (0, _computed.filterBy)('shifts', 'ConfirmedStartDT', null),
    finishedShiftsSortOrder: ['ConfirmedStartDT'],
    unfinishedShiftsSortOrder: ['PlannedStartDT'],
    actions: {
      updateState: function updateState(shift, state) {
        var _this = this;

        if (state.disabled) {
          return;
        }

        if (state.state === 'started') {
          // Start and Restart
          this._askForOdometer(shift, this.get('i18n').t('start_of_shift'), 'OdometerStart').then(function () {
            _this.get('f7').showPreloader();

            var startShift = state.action === 'restart' ? shift.restart() : shift.start();
            startShift.then(function () {
              _this._updateAppState(shift);

              _this.attrs['navigate-to-trip-page'](shift).finally(function () {
                _this.get('f7').hidePreloader();
              });
            });
          });
        } else if (state.state === 'finished') {
          this._askForOdometer(shift, this.get('i18n').t('end_of_shift'), 'OdometerEnd').then(function () {
            shift.finish().then(function () {
              return _this._updateAppState(shift);
            });
          });
        } else {
          shift.cancel().then(function () {
            return _this._updateAppState(shift);
          });
        }
      },
      selectShift: function selectShift(itemId) {
        var currentRoute = this.get('router').currentRouteName;
        if (currentRoute === 'trips.index') return;
        var appState = this.get('appState');
        var shift = this.get('store').peekRecord('shift', itemId);
        appState.setSelectedShift(itemId);
        appState.setProperties({
          currentTruck: shift.get('TruckID'),
          currentTrailer: shift.get('TrailerID')
        });
        appState.save();
      },
      onShiftTripNavigateClick: function onShiftTripNavigateClick(trip) {
        var _this2 = this;

        this._selectTrip(trip.get('id')).then(function () {
          _this2.attrs['on-shift-trip-navigate-click'](trip);
        });
      },
      selectTrip: function selectTrip(itemId) {
        this._selectTrip(itemId);
      }
    },
    _selectTrip: function _selectTrip(itemId) {
      var appState = this.get('appState');
      appState.setSelectedTrip(itemId);
      return appState.save();
    },
    _updateAppState: function _updateAppState(shift) {
      var appState = this.get('appState');
      appState.set('startedShift', null);
      appState.set('anyShiftStarted', shift.get('started'));

      if (shift.get('started')) {
        appState.set('startedShift', shift);
      }

      return appState.save();
    }
  });

  _exports.default = _default;
});