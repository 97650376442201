define("bottomline-execution/components/geographical-maps/navigation-map", ["exports", "bottomline-execution/components/geographical-maps/base-map", "@ember/object", "@ember/utils"], function (_exports, _baseMap, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShowAllPopupsControl = L.Control.extend({
    options: {
      position: 'topleft'
    },
    onAdd: function onAdd(map) {
      var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

      this._applyInfoIconStyle(container);

      container.onclick = function () {
        var showAllDescriptions = map.component.get('showingAllDescriptions');
        container.style.opacity = showAllDescriptions ? 1 : 0.5;
        var currentIcons = map.component.get('visibleIcons');
        if ((0, _utils.isEmpty)(currentIcons)) return;

        if (!showAllDescriptions) {
          var allIconsForMap = map.component.get('icons');
          var iconsWithPopup = [];
          currentIcons.forEach(function (icon) {
            return icon.openPopup();
          });
          allIconsForMap.forEach(function (icon) {
            return iconsWithPopup.push(icon.get('id'));
          });
          map.component.set('iconsWithPopup', iconsWithPopup);
        } else {
          currentIcons.forEach(function (icon) {
            return icon.closePopup();
          });
          map.component.set('iconsWithPopup', []);
        }

        map.component.set('showingAllDescriptions', !showAllDescriptions);
      };

      return container;
    },
    _applyInfoIconStyle: function _applyInfoIconStyle(container) {
      container.style.backgroundImage = "url(assets/info.png)";
      container.style.backgroundRepeat = 'no-repeat';
      container.style.backgroundColor = 'white';
      container.style.backgroundPosition = 'center';
      container.style.width = '30px';
      container.style.height = '30px';
      container.style.opacity = 1;
    }
  });

  var _default = _baseMap.default.extend({
    showingAllDescriptions: false,
    invertedWidth: false,
    includeCurrentLocationInInitialZoom: false,
    icons: [],
    iconsWithPopup: [],
    iconsForInitialZoom: [],
    iconClickHandler: null,
    currentRenderedObjectId: null,
    currentRenderedObjectIdChanged: (0, _object.observer)('currentRenderedObjectId', function () {
      this._applyDefaultZoom();
    }),
    iconsForMapChanged: (0, _object.observer)('icons.@each.{latitude,longitude}', function () {
      var geographicalMap = this.get('geographicalMap');

      this._renderIcons(geographicalMap);
    }),
    didInsertElement: function didInsertElement() {
      this._resetAttributes();

      this._renderMap();
    },
    _resetAttributes: function _resetAttributes() {
      this.set('iconsWithPopup', []);
    },
    _defaultMapBounds: function _defaultMapBounds(icons) {
      var coordinates = this._coordinatesBasedOnIcons(icons);

      return this._calculateMapBounds(coordinates);
    },
    _iconsToShowOnDefaultZoom: function _iconsToShowOnDefaultZoom() {
      var _this = this;

      var iconsToIncludeInDefaultZoom = this.get('iconsForInitialZoom').filter(function (icon) {
        return _this._hasLatitudeLongitude(icon);
      });
      var currentLocationIcon = this.get('currentLocationIcon');

      if ((0, _utils.isPresent)(currentLocationIcon) && this.get('includeCurrentLocationInInitialZoom')) {
        var coordinates = currentLocationIcon.getLatLng();
        iconsToIncludeInDefaultZoom.push(this._markerForIcons(currentLocationIcon, coordinates));
      }

      return iconsToIncludeInDefaultZoom;
    },
    _addControlsForMap: function _addControlsForMap(geographicalMap) {
      geographicalMap.addControl(new L.Control.Fullscreen());
      geographicalMap.addControl(new ShowAllPopupsControl());
    },
    _defineMapMoveHandler: function _defineMapMoveHandler(geographicalMap) {
      var _this2 = this;

      geographicalMap.on('load moveend', function () {
        var bounds = geographicalMap.getBounds();
        var boundsUrlString = [bounds.getSouth(), bounds.getWest(), bounds.getNorth(), bounds.getEast()].join(',');

        _this2.get('updateContentMapUsingNewBounds')(boundsUrlString);
      });
    },
    _renderIcons: function _renderIcons(geographicalMap) {
      if (this.isDestroying || this.isDestroyed) return;
      var icons = this.get('icons');
      if ((0, _utils.isEmpty)(icons)) return [];
      this.get('visibleIcons').forEach(function (icon) {
        return icon.remove();
      });

      this._addIcons(icons, geographicalMap);
    },
    _createIcon: function _createIcon(icon, coordinates, geographicalMap) {
      var _this3 = this;

      var iconToShow = new L.marker(coordinates, {
        title: icon.get('iconTitle'),
        icon: this._icon(icon),
        zIndexOffset: this._iconZIndex(icon),
        opacity: this._iconOpacity(icon)
      }).on('click', function () {
        _this3._getClickHandlerForIcon(icon);
      });

      this._bindPopup(iconToShow, icon);

      iconToShow.addTo(geographicalMap);
      return iconToShow;
    },
    _getClickHandlerForIcon: function _getClickHandlerForIcon(icon) {
      var iconId = icon.get('id');

      this._iconClicked(icon, iconId);
    },
    _iconClicked: function _iconClicked(icon, clickedIconId) {
      var unclickIcon = this.get('iconsWithPopup').includes(clickedIconId);

      if (unclickIcon) {
        var remainingSetOfClickedIcons = this.get('iconsWithPopup').reject(function (icon) {
          return icon == clickedIconId;
        });
        this.set('iconsWithPopup', remainingSetOfClickedIcons);
      } else {
        this.get('iconsWithPopup').push(clickedIconId);
      }

      var value = icon.get('idForMap') || icon.get('id');
      this.get('iconClickHandler')(value);
    }
  });

  _exports.default = _default;
});