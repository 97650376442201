define("bottomline-execution/controllers/trip-stops/load-stop", ["exports", "bottomline-execution/controllers/trip-stops/show", "@ember/object/computed", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _show, _computed, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _show.default.extend({
    store: (0, _service.inject)(),
    init: function init() {
      var showStandardLiters = this.get('truckSettings.show_standard_liters_on_load');
      this.set('showStandardLitersToggle', showStandardLiters === 'open');

      this._super.apply(this, arguments);
    },
    filterTruckPlannedCompartments: false,
    filterTrailerPlannedCompartments: false,
    isConfirmButtonEnabled: (0, _object.computed)('isProcessingConfirmAction', 'model.validations.isValid', function () {
      return !this.get('isProcessingConfirmAction') && this.get('model.validations.isValid');
    }),
    isCaptureRequired: (0, _object.computed)('model.hasPictures', function () {
      return this.get('truckSettings.on_load_mandatory_picture') && this.get('truckSettings.on_load_capture') && !this.get('model.hasPictures');
    }),
    isScanRequired: (0, _object.computed)('model.hasPictures', function () {
      return this.get('truckSettings.on_load_mandatory_picture') && this.get('truckSettings.on_load_scan') && !this.get('model.hasPictures');
    }),
    showAllTruckCompartments: (0, _object.computed)('filterTruckPlannedCompartments', 'selectedTripStopStorage', function () {
      return !this.get('filterTruckPlannedCompartments') || this.get('selectedTripStopStorage.plannedQty') < 1;
    }),
    showAllTrailerCompartments: (0, _object.computed)('filterTrailerPlannedCompartments', 'selectedTripStopStorage', function () {
      return !this.get('filterTrailerPlannedCompartments') || this.get('selectedTripStopStorage.plannedQty') < 1;
    }),
    showStandardLitersSwitch: (0, _object.computed)(function () {
      return ['open', 'closed'].includes(this.get('truckSettings.show_standard_liters_on_load'));
    }),
    showStandardLitersToggle: null,
    showStandardLiters: (0, _object.computed)('showStandardLitersToggle', function () {
      return this.get('truckSettings.show_standard_liters_on_load') === true || this.get('showStandardLitersToggle');
    }),
    showFilteredMaterials: (0, _object.computed)('truckSettings.show_materials_on_load', function () {
      return ['relevant', 'all'].includes(this.get('truckSettings.show_materials_on_load'));
    }),
    showAllMaterials: (0, _computed.equal)('truckSettings.show_materials_on_load', 'all'),
    loadAsPlanned: function loadAsPlanned(truckInventory) {
      var truckStorageId = truckInventory.get('truck_storage_id');
      var tripStopTruckStorage = this.get('model.trip_stop_truck_storages').findBy('TruckStorageID', truckStorageId);

      if (tripStopTruckStorage && tripStopTruckStorage.get('hasPlannedMaterial') && (0, _utils.isEmpty)(tripStopTruckStorage.get('confirmedQty'))) {
        tripStopTruckStorage.setConfirmedFromPlannedQty();
      }
    },
    resetCompartmentFilters: function resetCompartmentFilters() {
      this.set('filterTruckPlannedCompartments', false);
      this.set('filterTrailerPlannedCompartments', false);
    },
    actions: {
      getLoads: function getLoads() {
        var loadsImported = 0;

        if (this.get('model.trip_stop_storages').invoke('getLoad')) {
          loadsImported = loadsImported + 1;
        }

        if (loadsImported === 0) {
          alert('Load information not available');
        }
      },
      loadAsPlanned: function loadAsPlanned() {
        var _this = this;

        this.get('model.truckInventories').forEach(function (inventory) {
          _this.loadAsPlanned(inventory);
        });
        this.get('model.trailerInventories').forEach(function (inventory) {
          _this.loadAsPlanned(inventory);
        });
        this.get('model.trip_stop_storages').invoke('updateConfirmedQty');
      },
      selectTripStopStorage: function selectTripStopStorage(id) {
        this.set('selectedTripStopStorage', this.get('store').peekRecord('trip-stop-storage', id));
      },
      toggleShowFilteredMaterials: function toggleShowFilteredMaterials() {
        this.toggleProperty('showFilteredMaterials');

        if (!this.get('showFilteredMaterials')) {
          this.set('selectedTripStopStorage', null);
          this.resetCompartmentFilters();
          this.set('showAllMaterials', false);
        }

        return false;
      },
      toggleShowAllMaterials: function toggleShowAllMaterials() {
        this.toggleProperty('showAllMaterials');

        if (this.get('showAllMaterials')) {
          this.set('showFilteredMaterials', true);
        }
      },
      toggleFilterTruckPlannedCompartments: function toggleFilterTruckPlannedCompartments() {
        this.toggleProperty('filterTruckPlannedCompartments');
      },
      toggleFilterTrailerPlannedCompartments: function toggleFilterTrailerPlannedCompartments() {
        this.toggleProperty('filterTrailerPlannedCompartments');
      }
    }
  });

  _exports.default = _default;
});