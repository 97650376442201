define("bottomline-execution/controllers/shifts/index", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "bottomline-execution/mixins/map-element-data"], function (_exports, _controller, _object, _computed, _service, _utils, _mapElementData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_mapElementData.default, {
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    appState: (0, _computed.alias)('shared.appState'),
    selectedTripId: (0, _computed.alias)('appState.selectedTrip'),
    selectedTrip: (0, _object.computed)('selectedTripId', function () {
      if ((0, _utils.isPresent)(this.get('selectedTripId'))) {
        return this.store.peekRecord('trip', this.get('selectedTripId'));
      }
    }),
    selectedShiftId: (0, _computed.alias)('appState.selectedShift'),
    selectedShift: (0, _object.computed)('selectedShiftId', function () {
      if ((0, _utils.isPresent)(this.get('selectedShiftId'))) {
        return this.store.peekRecord('shift', this.get('selectedShiftId'));
      }
    }),
    tripsOnTheMap: (0, _object.computed)('selectedShift.tripStops.[]', 'selectedTrip', function () {
      var _this = this;

      return this.get('selectedShift.tripStops').reduce(function (collection, tripStop) {
        var selectedTripStops = _this.get('appState.trip.tripStops');

        var isSelected = (selectedTripStops || []).includes(tripStop);

        var tripStopOnMap = _this.getTripStopOnMapInfo(tripStop, isSelected);

        collection.pushObject(tripStopOnMap);

        var tanksOnMap = _this.getTripStopTanksMapInfo(tripStop, isSelected);

        collection.pushObjects(tanksOnMap);
        return collection;
      }, []);
    }),
    iconSetForInitialZoomGeoMap: (0, _object.computed)('tripsOnTheMap', function () {
      this.set('includeCurrentLocationInZoomGeoMap', false);
      var tripsOnTheMap = this.get('tripsOnTheMap');
      var unloadStopsOnTheMap = tripsOnTheMap.filter(function (trip) {
        return trip.get('tripStop.isUnload');
      });

      if ((0, _utils.isPresent)(unloadStopsOnTheMap)) {
        return unloadStopsOnTheMap;
      } else {
        this.set('includeCurrentLocationInZoomGeoMap', true);
        return tripsOnTheMap;
      }
    }),
    includeCurrentLocationInZoomGeoMap: false,
    actions: {
      navigateToTripPage: function navigateToTripPage(shift) {
        return this.router.transitionTo('trips.index', shift.id);
      },
      onShiftTripNavigateClick: function onShiftTripNavigateClick(trip) {
        trip.set('isShown', true);
        this.router.transitionTo('trips.index', trip.get('shift.id'));
      },
      setSelectedTrip: function setSelectedTrip(tripId) {
        this.set('appState.selectedTrip', tripId.toString());
      },
      setSelectedTripFromTripStop: function setSelectedTripFromTripStop(tripStopId) {
        var tripId = this.get('store').peekRecord('trip-stop', tripStopId).get('TripID');
        this.send('setSelectedTrip', tripId);
      }
    }
  });

  _exports.default = _default;
});