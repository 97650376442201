define("bottomline-execution/controllers/trip-stops/unload-stop", ["exports", "bottomline-execution/controllers/trip-stops/show", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/utils", "ember-data", "bottomline-execution/mixins/map-element-data", "bottomline-execution/utils/delivery-ticket-nb", "bottomline-execution/utils/guid-util", "bottomline-execution/utils/is-zero-or-greater", "bottomline-execution/mixins/planned-or-confirmed-trip-stop-storages"], function (_exports, _show, _computed, _object, _service, _utils, _emberData, _mapElementData, _deliveryTicketNb, _guidUtil, _isZeroOrGreater, _plannedOrConfirmedTripStopStorages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _show.default.extend(_mapElementData.default, _plannedOrConfirmedTripStopStorages.default, {
    remoteLogging: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    init: function init() {
      var showStandardLiters = this.get('truckSettings.show_standard_liters_on_unload');
      this.set('showStandardLitersToggle', showStandardLiters === 'open');

      this._super.apply(this, arguments);
    },
    filterTruckPlannedCompartments: true,
    filterTrailerPlannedCompartments: true,
    _findOrCreateTripStopStorageFor: function _findOrCreateTripStopStorageFor(storage) {
      var tripStopStorages = this.get('model.trip_stop_storages');
      var storageId = String(storage.get('id'));
      var tripStopStorage = tripStopStorages.find(function (tss) {
        return String(tss.get('storage.id')) === storageId;
      });
      if ((0, _utils.isPresent)(tripStopStorage)) return tripStopStorage;
      return this.get('model.trip_stop_storages').createRecord({
        id: (0, _guidUtil.guidUtil)(),
        plannedQty: 0,
        storage: storage,
        StorageID: storage.id,
        TripID: this.get('model.TripID'),
        TripStopID: this.get('model.id'),
        MaterialID: storage.current_material_id
      });
    },

    get returnableMaterials() {
      var _this = this;

      var promise = this.get('model.site.storages').then(function (siteStorages) {
        return siteStorages.filterBy('hasReturnableMaterial').sortBy('SequenceNb', 'currentMaterial.MaterialNameLong').map(function (storage) {
          return _this._findOrCreateTripStopStorageFor(storage);
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    },

    showAtgLastReading: (0, _object.computed)('visibleTripStopStorages.[]', function () {
      return this.get('visibleTripStopStorages').any(function (tss) {
        return (0, _utils.isPresent)(tss.get('storage.recent_inventory.InventoryDT'));
      });
    }),
    showAtgCurrentQty: (0, _object.computed)('visibleTripStopStorages.[]', function () {
      return this.get('visibleTripStopStorages').any(function (tss) {
        return (0, _utils.isPresent)(tss.get('storage.recent_inventory.inventoryQty'));
      });
    }),
    showAtgUllage: (0, _object.computed)('visibleTripStopStorages.[]', function () {
      return this.get('visibleTripStopStorages').any(function (tss) {
        return (0, _utils.isPresent)(tss.get('storage.availableQty'));
      });
    }),
    inventories: (0, _object.computed)('showUnplannedStorages', 'truckSettings.dip_unplanned_storages', function () {
      var _this2 = this;

      if (this.get('showUnplannedStorages') && this.get('truckSettings.dip_unplanned_storages')) {
        var storageGroupId = this.get('model.StorageGroupID');
        var promise = this.get('model.site.storages').then(function (siteStorages) {
          return siteStorages.filter(function (storage) {
            return storage.get('StorageGroupID') != storageGroupId;
          }).reduce(function (inventories, storage) {
            var newInventory = _this2.get('store').createRecord('inventory', {
              id: (0, _guidUtil.guidUtil)(),
              storage: storage,
              StorageID: storage.get('id'),
              InventoryDT: moment(),
              inventoryQty: 0,
              tripStopId: _this2.get('model.id')
            });

            if (newInventory.get('canBeUpdated')) {
              storage.set('recent_inventory', newInventory);
              inventories.push(newInventory);
            }

            return inventories;
          }, []);
        });
        return _emberData.default.PromiseArray.create({
          promise: promise
        });
      } else {
        return [];
      }
    }),
    showAmbientLiters: (0, _computed.alias)('truckSettings.show_ambient_liters_on_unload'),
    showAllTruckCompartments: (0, _object.computed)('filterTruckPlannedCompartments', 'selectedTripStopStorage', function () {
      return !this.get('filterTruckPlannedCompartments') || this.get('selectedTripStopStorage.plannedQty') < 1;
    }),
    showAllTrailerCompartments: (0, _object.computed)('filterTrailerPlannedCompartments', 'selectedTripStopStorage', function () {
      return !this.get('filterTrailerPlannedCompartments') || this.get('selectedTripStopStorage.plannedQty') < 1;
    }),
    showAtgInformation: (0, _computed.alias)('truckSettings.show_atg_information'),
    showStandardLitersSwitch: (0, _object.computed)(function () {
      return ['open', 'closed'].includes(this.get('truckSettings.show_standard_liters_on_unload'));
    }),
    showStandardLitersToggle: null,
    showStandardLiters: (0, _object.computed)('showStandardLitersToggle', function () {
      return this.get('truckSettings.show_standard_liters_on_unload') === true || this.get('showStandardLitersToggle');
    }),
    showTemperature: (0, _computed.alias)('truckSettings.show_temperature_on_unload'),
    showDeliveryTicketInput: (0, _object.computed)('truckSettings.delivery_ticketnb_per_tank', 'truckSettings.hide_delivery_ticket_input', function () {
      return !this.get('truckSettings.delivery_ticketnb_per_tank') && !this.get('truckSettings.hide_delivery_ticket_input');
    }),
    showReturnMaterialsToggle: true,
    showUnplannedStorages: false,
    visibleTripStopStorages: (0, _object.computed)('model.trip_stop_storages.@each.storage', 'showUnplannedStorages', function () {
      var showUnplannedStorages = this.get('showUnplannedStorages');
      return this.plannedOrConfirmedTripStopStorages({
        tripStop: this.get('model'),
        tripStopStorages: this.get('model.trip_stop_storages'),
        includeUnplannedStorages: showUnplannedStorages,
        storageGroupID: this.get('model.StorageGroupID')
      }).filterBy('hasReturnableMaterial', false).sortBy('storage.SequenceNb');
    }),
    // Discharge plan
    showDischargePlanButton: (0, _computed.alias)('truckSettings.dischargeplan_on_unload'),
    isOpeningDischargePlan: false,
    // Location
    enableSiteLocationUpdate: (0, _computed.alias)('truckSettings.register_site_coordinates'),
    enableTankLocationUpdate: (0, _computed.alias)('truckSettings.register_tank_coordinates'),
    locationConfirmedId: null,
    locationService: (0, _service.inject)('location'),
    geoMapElementUpdateSite: (0, _object.computed)(function () {
      var tripStop = this.get('model');
      return this.getUpdateSiteOnMapInfo(tripStop);
    }),
    tankToUpdateLocation: null,
    geoMapElementUpdateTank: (0, _object.computed)('tankToUpdateLocation', function () {
      var tank = this.get('tankToUpdateLocation');
      var tripStop = this.get('model');
      return this.getUpdateStorageOnMapInfo(tank, tripStop);
    }),
    // Meter coupling
    _assignMeterDeliveriesData: function _assignMeterDeliveriesData(meterDeliveriesData, storagesToDeliver) {
      var _this3 = this;

      var total = function total(value) {
        return value == 'null' ? 0 : value;
      };

      this.set('meterDeliveriesData', meterDeliveriesData);
      meterDeliveriesData.forEach(function (deliveryData, index) {
        var validDelivery = (0, _isZeroOrGreater.isZeroOrGreater)(Number(deliveryData.volume));

        if (validDelivery) {
          var meterDeliveryInfo = "MeterId=".concat(deliveryData.meterId, ";                                   CounterStart=").concat(total(deliveryData.totalVolumeStart), ";                                   CounterEnd=").concat(total(deliveryData.totalVolumeEnd), ";                                   Counter15Start=").concat(total(deliveryData.totalVolume15cStart), ";                                   Counter15End=").concat(total(deliveryData.totalVolume15cEnd)).replace(/\s/g, "");
          var deliveryStoragAttributes = {
            confirmedQty: deliveryData.volume,
            meterQty: deliveryData.volume,
            TicketNb: (0, _deliveryTicketNb.deliveryTicketNb)(_this3.get('appState.currentTruck'), deliveryData.meterIndex, deliveryData.ticketNb),
            MeterDeliveryInfo: meterDeliveryInfo
          };
          if (deliveryData.volume15c !== 'null') deliveryStoragAttributes.confirmed15Qty = deliveryData.volume15c;
          if (deliveryData.temperature !== 'null') deliveryStoragAttributes.Temperature = deliveryData.temperature;
          storagesToDeliver.objectAt(index).setProperties(deliveryStoragAttributes);
        }
      });
    },
    _prepareAndValidateForMeter: function _prepareAndValidateForMeter(storagesToDeliver) {
      var plannedDeliveries = storagesToDeliver.map(function (storage) {
        return {
          productId: String(storage.get('material.meter_material_number')),
          volume: String(storage.get('plannedQty'))
        };
      });

      if (plannedDeliveries.mapBy('productId').every(function (productId) {
        return (0, _utils.isPresent)(productId);
      })) {
        return plannedDeliveries;
      } else {
        this.get('f7').alert(this.get('i18n').t('trip-stop.meter_material_number.unavailable'), '');
        return [];
      }
    },
    canNotChangeDeliveries: (0, _computed.alias)('meterCoupling.isBusy'),
    canRunDeliveriesProcessing: (0, _object.computed)('visibleTripStopStorages.@each.processDelivery', 'meterCoupling.isAvailable', function () {
      var deliveriesToProcess = this.get('visibleTripStopStorages').filter(function (storage) {
        return storage.get('processDelivery');
      });
      return (0, _utils.isPresent)(deliveriesToProcess) && this.get('meterCoupling.isAvailable');
    }),
    isCaptureRequired: (0, _object.computed)('model.hasPictures', function () {
      return this.get('truckSettings.on_delivery_mandatory_picture') && this.get('truckSettings.on_delivery_capture') && !this.get('model.hasPictures');
    }),
    isMeterDeliveriesDataPresent: (0, _object.computed)('meterDeliveriesData', function () {
      return (0, _utils.isPresent)(this.get('meterDeliveriesData'));
    }),
    isProcessingDeliveries: (0, _computed.alias)('meterCoupling.isProcessingDeliveries'),
    isScanRequired: (0, _object.computed)('model.hasPictures', function () {
      return this.get('truckSettings.on_delivery_mandatory_picture') && this.get('truckSettings.on_delivery_scan') && !this.get('model.hasPictures');
    }),
    meterCoupling: (0, _service.inject)(),
    meterDeliveriesData: [],
    // Pre- and post-dip
    editablePredip: (0, _computed.equal)('truckSettings.predip_enabled', 'on_unload'),
    editableUllage: (0, _computed.equal)('truckSettings.predip_enabled', 'on_unload_by_ullage'),
    postdipCalculatedByUllage: (0, _object.computed)('truckSettings.postdip_enabled', function () {
      return ['optional_by_ullage', 'required_by_ullage'].includes(this.get('truckSettings.postdip_enabled'));
    }),
    showPostdip: (0, _object.computed)('truckSettings.postdip_enabled', function () {
      return ['optional', 'required'].includes(this.get('truckSettings.postdip_enabled'));
    }),
    resetCompartmentFilters: function resetCompartmentFilters() {
      this.set('filterTruckPlannedCompartments', true);
      this.set('filterTrailerPlannedCompartments', true);
    },
    showPredip: (0, _computed.bool)('truckSettings.predip_enabled'),
    actions: {
      confirm: function confirm() {
        var _this4 = this;

        this.set('isProcessingConfirmAction', true);
        var postDipsInvalidMessage = this.get('model.validations.warnings').filterBy('attribute', 'PostDipsOnUnloadValid').mapBy('message').join(',');

        if ((0, _utils.isPresent)(postDipsInvalidMessage)) {
          var formattedMessage = postDipsInvalidMessage.split(',').join('<p>');
          this.get('f7').modal({
            title: this.get('i18n').t('postdip_validation.title'),
            text: this.get('i18n').t('postdip_validation.warning') + '<p>' + formattedMessage,
            verticalButtons: true,
            buttons: [{
              text: this.get('i18n').t('postdip_validation.cancel')
            }, {
              text: this.get('i18n').t('postdip_validation.confirm'),
              bold: true,
              onClick: function onClick() {
                _this4._confirmTripStop();
              }
            }]
          });
        } else {
          this.set('showUnplannedStorages', false);

          this._confirmTripStop();
        }
      },
      selectTripStopStorage: function selectTripStopStorage(id) {
        this.set('selectedTripStopStorage', this.get('store').peekRecord('trip-stop-storage', id));
        this.resetCompartmentFilters();
      },
      toggleFilterTruckPlannedCompartments: function toggleFilterTruckPlannedCompartments() {
        this.toggleProperty('filterTruckPlannedCompartments');
      },
      toggleFilterTrailerPlannedCompartments: function toggleFilterTrailerPlannedCompartments() {
        this.toggleProperty('filterTrailerPlannedCompartments');
      },
      toggleUnplannedStorages: function toggleUnplannedStorages() {
        if (this.get('isProcessingDeliveries')) {
          return false;
        }

        this.toggleProperty('showUnplannedStorages');
      },
      toggleDeliveryToProcess: function toggleDeliveryToProcess(tripStopStorageId) {
        var tripStopStorage = this.get('visibleTripStopStorages').find(function (storage) {
          return storage.get('id') == tripStopStorageId;
        });

        if ((0, _utils.isPresent)(tripStopStorage)) {
          tripStopStorage.toggleProperty('processDeliveryFlag');
        }
      },
      // Discharge plan
      openDischargePlan: function openDischargePlan() {
        var _this5 = this;

        var tripStopId = this.get('model.id');

        var flagOpeningDischargePlan = function flagOpeningDischargePlan(opening) {
          _this5.set('isOpeningDischargePlan', opening);
        };

        var errorOpeningDocument = function errorOpeningDocument(_error) {
          _this5.get('f7').alert(_this5.get('i18n').t('trip-stop.dischargePlan.unavailable'), '');
        };

        var pdfDocument = this.get('store').createRecord('pdf-document', {
          documentType: 'dischargePlan',
          modelId: tripStopId
        });
        pdfDocument.open({
          signalOpening: flagOpeningDischargePlan,
          showWarning: errorOpeningDocument
        });
      },
      // location
      saveStorageNewLocation: function saveStorageNewLocation(storage, latitude, longitude) {
        storage.setProperties({
          latitude: latitude,
          longitude: longitude
        });
        return storage.save();
      },
      saveSiteNewLocation: function saveSiteNewLocation(tripStop, latitude, longitude) {
        var site = tripStop.get('site');
        site.setProperties({
          latitude: latitude,
          longitude: longitude
        });
        tripStop.setProperties({
          latitude: latitude,
          longitude: longitude
        });
        return site.save();
      },
      showLocationConfirmer: function showLocationConfirmer(id, tank) {
        var _this6 = this;

        this.set('locationConfirmedId', null);
        setTimeout(function () {
          _this6.set('locationConfirmedId', id);

          _this6.set('tankToUpdateLocation', tank);
        }, 500);
      },
      cancelUpdateLocation: function cancelUpdateLocation() {
        this.set('locationConfirmedId', null);
      },
      // Meter coupling
      processDeliveries: function processDeliveries() {
        var _this7 = this;

        var storagesToDeliver = this.get('visibleTripStopStorages').filterBy('processDelivery');

        var deliveriesData = this._prepareAndValidateForMeter(storagesToDeliver);

        if ((0, _utils.isEmpty)(deliveriesData)) return false;
        storagesToDeliver.setEach('MeterDeliveryInfo', 'No data from meter coupling service received');
        this.get('meterCoupling').connectAndProcessDeliveries(this.get('model'), deliveriesData).then(function (meterDeliveriesData) {
          return _this7._assignMeterDeliveriesData(meterDeliveriesData, storagesToDeliver);
        }).catch(function (error) {
          _this7.get('remoteLogging').log("Error processing deliveries: ".concat(error.message), 'ERROR');

          _this7.get('toast').error(_this7.get('i18n').t(error.i18nKey));
        });
        return false;
      },
      stopProcessDeliveries: function stopProcessDeliveries() {
        var _this8 = this;

        this.get('meterCoupling').cancelDeliveries().then(function (completedDeliveries) {
          var storagesToDeliver = _this8.get('visibleTripStopStorages').filterBy('processDelivery');

          _this8._assignMeterDeliveriesData(completedDeliveries, storagesToDeliver);
        }).catch(function (error) {
          _this8.get('remoteLogging').log("Error cancelling deliveries: ".concat(error.message), 'ERROR');

          _this8.get('toast').error(_this8.get('i18n').t(error.i18nKey));
        });
        return false;
      }
    }
  });

  _exports.default = _default;
});