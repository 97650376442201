define("bottomline-execution/serializers/trip", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "ember-data", "active-model-adapter"], function (_exports, _object, _computed, _service, _utils, _emberData, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    appState: (0, _computed.alias)('shared.appState'),
    session: (0, _service.inject)('session'),
    authenticatedUser: (0, _computed.alias)('session.session.content.authenticated'),
    isNewSerializerAPI: true,
    attrs: {
      trip_stops: {
        embedded: 'always'
      },
      resource: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, resourceHash, _prop) {
      resourceHash.trip_stops.forEach(function (tripStopHash) {
        return tripStopHash['type'] = 'trip-stop';
      });
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.purpose === 'forReport') {
        return this.forReport(snapshot);
      } else {
        return this.forServer(snapshot);
      }
    },
    forReport: function forReport(snapshot) {
      var _this = this;

      var truckInventories = snapshot.belongsTo('shift').hasMany('truck_inventories').map(function (t) {
        return t.serialize({
          purpose: 'forReport'
        });
      });
      var tripStops = snapshot.hasMany('trip_stops').map(function (tripStopSnapshot) {
        var customAttributes = {
          id: tripStopSnapshot.id,
          activityCode: tripStopSnapshot.attr('ActivityCode'),
          allDriverRemarks: _this._allDriverRemarks(tripStopSnapshot.record),
          extOrderId: tripStopSnapshot.attr('ExtOrderID'),
          sequenceNb: tripStopSnapshot.attr('SequenceNb'),
          site: _this._site(tripStopSnapshot),
          tripStopStorages: _this._tripStopStorages(tripStopSnapshot),
          tripStopTruckStorages: _this._tripStopTruckStorages(tripStopSnapshot)
        };
        return Object.assign({}, tripStopSnapshot.attributes(), customAttributes);
      });
      return {
        appState: (0, _object.getProperties)(this.get('appState'), ['currentTruck', 'currentTrailer', 'language']),
        shift: snapshot.belongsTo('shift').attributes(),
        shiftId: snapshot.belongsTo('shift').id,
        displayName: this.get('authenticatedUser.user_display_name'),
        state: snapshot.attr('state'),
        truckId: snapshot.attr('TruckID'),
        Status: snapshot.attr('state') === 'planned' ? '1' : '2',
        totalDuration: snapshot.attr('TotalDuration'),
        totalDistance: snapshot.attr('TotalDistance'),
        confirmedSequenceNb: snapshot.attr('ConfirmedSequenceNb'),
        confirmedStartDt: snapshot.attr('ConfirmedStartDT'),
        confirmedEndDt: snapshot.attr('ConfirmedEndDT'),
        plannedDeliveryQty: snapshot.attr('PlannedDeliveryQty'),
        plannedSequenceNb: snapshot.attr('SequenceNb'),
        plannedStartDT: snapshot.attr('PlannedStartDT'),
        odometerStart: snapshot.attr('OdometerStart'),
        odometerEnd: snapshot.attr('OdometerEnd'),
        tripStops: tripStops,
        truckInventories: truckInventories
      };
    },
    _site: function _site(snapshot) {
      var site = snapshot.belongsTo('site');

      if ((0, _utils.isPresent)(site)) {
        return site.serialize({
          purpose: 'forReport'
        });
      } else {
        return {};
      }
    },
    _tripStopStorages: function _tripStopStorages(snapshot) {
      return snapshot.hasMany('trip_stop_storages').map(function (tss) {
        return tss.serialize({
          purpose: 'forReport'
        });
      });
    },
    _tripStopTruckStorages: function _tripStopTruckStorages(snapshot) {
      return (snapshot.hasMany('trip_stop_truck_storages') || []).map(function (tsts) {
        return tsts.serialize({
          purpose: 'forReport'
        });
      });
    },
    _allDriverRemarks: function _allDriverRemarks(tripStop) {
      return tripStop.get('allRemarks').map(function (remark) {
        return {
          type: 'site',
          body: remark
        };
      });
    },
    forServer: function forServer(snapshot) {
      var truckInventories = snapshot.belongsTo('shift').hasMany('truck_inventories').map(function (t) {
        return t.serialize();
      });
      var tripStops = snapshot.hasMany('trip_stops').map(function (ts) {
        return ts.serialize({
          purpose: 'forTrip'
        });
      });
      return {
        state: snapshot.attr('state'),
        ConfirmedSequenceNb: snapshot.attr('ConfirmedSequenceNb'),
        ConfirmedStartDT: snapshot.attr('ConfirmedStartDT'),
        ConfirmedEndDT: snapshot.attr('ConfirmedEndDT'),
        OdometerStart: snapshot.attr('OdometerStart'),
        OdometerEnd: snapshot.attr('OdometerEnd'),
        trip_stop_attributes: tripStops,
        truck_inventory_attributes: truckInventories
      };
    }
  });

  _exports.default = _default;
});