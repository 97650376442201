define("bottomline-execution/components/external-link", ["exports", "bottomline-execution/components/bl-components/link-button", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _linkButton, _computed, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _linkButton.default.extend({
    appState: (0, _computed.alias)('shared.appState'),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    session: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    href: (0, _object.computed)(function () {
      return this._buildUrl();
    }),
    _buildUrl: function _buildUrl() {
      var _this = this;

      var _this$get$toLowerCase = this.get('truckSettings.external_reporting_url').toLowerCase().split('?'),
          _this$get$toLowerCase2 = _slicedToArray(_this$get$toLowerCase, 2),
          baseUrl = _this$get$toLowerCase2[0],
          _this$get$toLowerCase3 = _this$get$toLowerCase2[1],
          queryTemplate = _this$get$toLowerCase3 === void 0 ? '' : _this$get$toLowerCase3;

      var queryString = queryTemplate.split('&').map(function (key) {
        var propertyName = _this.get('keyLookup')[key] || '';

        if ((0, _utils.isEmpty)(propertyName)) {
          _this.get('toast').error("Unknown key '".concat(key, "' for external URL query"));
        }

        var value = _this.get(propertyName);

        return (0, _utils.isEmpty)(value) ? null : "".concat(key, "=").concat(value);
      }).compact().join('&');
      return baseUrl + '?' + encodeURI(queryString);
    },
    keyLookup: {
      customeraddrs: 'site.Address',
      customercity: 'site.City',
      customername: 'site.Name',
      customerphone: 'site.Telephone',
      customerzip: 'site.ZipCode',
      deliveredvl: 'deliveredVolume',
      license: 'truck.License',
      loadid: 'loadId',
      orderid: 'orderId',
      ordervl: 'orderVolume',
      seller: 'site.Contact',
      tank: 'tank',
      targetid: 'site.LoadingID',
      useremail: 'sessionData.user_email',
      username: 'sessionData.user_display_name',
      userphone: 'sessionData.user_phone'
    },
    deliveredVolume: (0, _object.computed)(function () {
      if (!this.get('hasSinglePlannedUnload')) return '';
      return this.get('tripStopStorage.confirmedQty');
    }),
    hasSinglePlannedStorage: (0, _object.computed)(function () {
      return this.get('tripStop.tripStopStorageWithPlanned').length == 1;
    }),
    hasSinglePlannedUnload: (0, _object.computed)(function () {
      return !this.get('tripStop.isLoad') && this.get('hasSinglePlannedStorage');
    }),
    loadId: (0, _object.computed)(function () {
      return this.get('tripStop.trip.loadStop.ExtOrderID');
    }),
    orderId: (0, _object.computed)(function () {
      if (this.get('tripStop.isLoad')) return '';
      return this.get('tripStop.ExtOrderID');
    }),
    orderVolume: (0, _object.computed)(function () {
      if (!this.get('hasSinglePlannedUnload')) return '';
      return this.get('tripStopStorage.plannedQty');
    }),
    sessionData: (0, _computed.alias)('session.session.authenticated'),
    site: (0, _computed.alias)('tripStop.site'),
    tank: (0, _object.computed)(function () {
      if (!this.get('hasSinglePlannedUnload')) return '';
      var sequenceNb = this.get('tripStopStorage.storage.SequenceNb');
      var materialName = this.get('tripStopStorage.material.MaterialNameShort');
      return "".concat(sequenceNb, " ").concat(materialName);
    }),
    tripStopStorage: (0, _object.computed)(function () {
      return this.get('tripStop.tripStopStorageWithPlanned.firstObject');
    }),
    truck: (0, _object.computed)(function () {
      return this.get('appState').getTruck();
    })
  });

  _exports.default = _default;
});