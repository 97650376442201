define("bottomline-execution/components/trip-stop-storage-overview", ["exports", "@ember/object/computed", "@ember/component", "@ember/object"], function (_exports, _computed, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    compartments: (0, _computed.alias)('truckSettings.compartments'),
    loadTicketnbPerTank: (0, _computed.alias)('truckSettings.load_ticketnb_per_tank'),
    filteredMaterials: (0, _object.computed)('allMaterials', 'relevantMaterials', 'parentController.showAllMaterials', function () {
      if (this.get('parentController.showAllMaterials')) return this.get('allMaterials');
      return this.get('relevantMaterials');
    }),
    allMaterials: (0, _computed.alias)('model.trip_stop_storages'),
    relevantMaterials: (0, _object.computed)('model.trip_stop_storages', function () {
      return this.get('model.trip_stop_storages').filter(function (item) {
        return item.get('plannedQty') > 0 || item.get('totalQty') > 0 || item.get('totalQty') < 0;
      });
    }),
    showAmbientLiters: (0, _computed.alias)('truckSettings.show_ambient_liters_on_load'),
    showDensity: (0, _computed.alias)('truckSettings.show_density_on_load'),
    showTemperature: (0, _computed.alias)('truckSettings.show_temperature_on_load')
  });

  _exports.default = _default;
});