define("bottomline-execution/templates/components/geographical-maps/location-update-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GhYZH8zF",
    "block": "[[[10,0],[14,0,\"location-update-map\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"to\"],[\"location-update-map\"]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"location-update-map__location-confirmer-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"location-update-map__location-confirmer-map-container\"],[14,1,\"geographicalMap\"],[12],[13],[1,\"\\n      \"],[10,2],[14,0,\"buttons-row\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"actionOnClick\",\"translationKey\",\"styleGroups\"],[[28,[37,2],[[30,0],\"confirm\"],null],\"confirm\",\"execution-form-action-button execution-form-confirm-button\"]]]],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"actionOnClick\",\"translationKey\",\"styleGroups\"],[[28,[37,2],[[30,0],\"cancel\"],null],\"cancel\",\"execution-form-action-button\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[13]],[],false,[\"ember-wormhole\",\"bl-components/button\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/geographical-maps/location-update-map.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});