define("bottomline-execution/controllers/shifts/adr-report", ["exports", "@ember/array", "@ember/controller", "@ember/utils", "@ember/object", "@ember/object/computed", "bottomline-execution/utils/sort-utils", "@ember/service", "moment"], function (_exports, _array, _controller, _utils, _object, _computed, _sortUtils, _service, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    i18n: (0, _service.inject)(),
    store: (0, _service.inject)(),
    session: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    authenticatedUser: (0, _computed.alias)('session.session.content.authenticated'),
    jsreport: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    currentInventories: (0, _array.A)([]),
    doc: null,
    appState: (0, _computed.alias)('shared.appState'),
    selectedShift: (0, _object.computed)(function () {
      var selectedShiftId = this.get('appState.selectedshift') || -1;
      return this.get('store').peekRecord('shift', selectedShiftId);
    }),
    shifts: (0, _object.computed)('store', function () {
      return this.get('store').peekAll('shift');
    }),
    startedShift: (0, _object.computed)('shifts.@each.state', function () {
      return this.get('shifts').findBy('state', 'started');
    }),
    adrReportTemplateName: (0, _object.computed)(function () {
      return this.get('appState.shift.adr_report_template_name');
    }),
    startedTrip: function startedTrip() {
      return this.get('store').peekAll('trip').findBy('state', 'started');
    },
    mostRecentLoadTerminal: function mostRecentLoadTerminal(recentLoadTripStop) {
      // latest load stop should be based on the fetched trip-stop while starting the shift
      // or reloading app when shift
      var currentLoadStop = recentLoadTripStop; // GET the raw data

      console.info("check if trip is started, current load stop is:", recentLoadTripStop);
      var startedTrip = this.startedTrip();

      if ((0, _utils.isPresent)(startedTrip)) {
        console.info("started trip is present:", startedTrip);
        var finishedLoadStopInCurrentTrip = this.get('store').peekAll('trip-stop').find(function (tripStop) {
          return tripStop.get('state') === 'finished' && tripStop.get('isLoad') && Number(tripStop.get('TripID')) === Number(startedTrip.id);
        });
        console.info("using loaded trip from currently started trip:", finishedLoadStopInCurrentTrip);

        if ((0, _utils.isPresent)(finishedLoadStopInCurrentTrip)) {
          console.info("Setting var to return it");
          currentLoadStop = finishedLoadStopInCurrentTrip;
        }
      }

      console.info("return current Load Stop: ", currentLoadStop);
      return currentLoadStop;
    },
    adrDate: (0, _object.computed)(function () {
      return (0, _moment.default)().format('L');
    }),
    adrTime: (0, _object.computed)(function () {
      return (0, _moment.default)().format('LT');
    }),
    adrTruckId: (0, _object.computed)(function () {
      return this.get('appState.currentTruck');
    }),
    adrTrailerId: (0, _object.computed)(function () {
      return this.get('selectedShift.TrailerID');
    }),
    adrLicenseTruck: (0, _object.computed)('appState.currentTruck', function () {
      return this.get('appState').getTruck().get('License') || '';
    }),
    adrLicenseTrailer: (0, _object.computed)('appState.currentTrailer', function () {
      return this.get('appState').getTrailer().get('License') || '';
    }),
    adrHaulierName: (0, _object.computed)('appState.currentTruck', function () {
      return this.get('currentTruck.haulier_description') || '';
    }),
    adrDriverName: (0, _object.computed)('selectedShift', function () {
      return this.get('session.session.content.authenticated.user_display_name') || this.get('session.session.content.authenticated.user_name');
    }),
    currentTruck: (0, _object.computed)('appState.currentTruck', function () {
      return this.get('store').peekRecord('truck', this.get('appState.currentTruck'));
    }),
    currentTrip: (0, _object.computed)('appState.selectedTrip', function () {
      var selectedTripId = this.get('appState.selectedTrip') || -1;
      return this.get('store').peekRecord('trip', selectedTripId);
    }),
    currentTripStop: (0, _object.computed)('appState.selectedTripStop', function () {
      var selectedTripStopId = this.get('appState.selectedTripStop') || -1;
      return this.get('store').peekRecord('trip-stop', selectedTripStopId);
    }),
    sortedTruckInventories: (0, _object.computed)('truckInventories.[]', function () {
      return this.get('truckInventories').sort(function (a, b) {
        return (0, _sortUtils.alphaNumericSort)(a.get('truck_storage_id'), b.get('truck_storage_id'));
      });
    }),
    truckInventories: (0, _object.computed)(function () {
      if (this.get('appState.truckSettings.compartments')) {
        return this.get('startedShift.truck_inventories').filter(function (item) {
          return item.get('isCompartmentInventory');
        });
      } else {
        return this.get('startedShift.truck_inventories').filter(function (item) {
          return item.get('isMaterialInventory');
        }).filter(function (item) {
          return Number(item.get('currentQty')) > 0;
        });
      }
    }),
    canGenerateADRReport: function canGenerateADRReport() {
      return (0, _utils.isPresent)(this.get('adrReportTemplateName'));
    },
    generateADRReport: function generateADRReport() {
      var adrReportTemplateName = this.get('adrReportTemplateName');
      var recentLoadStopId = -1;
      var recentLoadTripStopPayload = this.get('store').get('shiftMeta').recentLoadTripStop;

      if ((0, _utils.isPresent)(recentLoadTripStopPayload)) {
        recentLoadStopId = recentLoadTripStopPayload.trip_stop.id;
      }

      return this._jsreportsAdr(adrReportTemplateName, recentLoadStopId);
    },
    // generate ADR with jsreports
    // TODO: it will be refactored after we will switch to jsreports completely
    _jsreportsAdr: function _jsreportsAdr(reportTemplateName, recentLoadTripStopId) {
      var _this = this;

      var reportFileName = 'adr-report.pdf';
      var recentLoadTripStop = this.get('store').peekRecord('trip-stop', recentLoadTripStopId);
      var loadSite = this.mostRecentLoadTerminal(recentLoadTripStop).get('site');
      var loadSiteAddress;

      if (loadSite) {
        loadSiteAddress = loadSite.get('Name');
        loadSiteAddress += " - ".concat(loadSite.get('fullAddress'));
        var telephone = loadSite.get('telephoneNumbered');
        telephone ? loadSiteAddress += ", tel.: ".concat(telephone) : null;
        loadSiteAddress = loadSiteAddress.replace(/^\s+/gm, '');
      }

      var truckId = this.get('adrTruckId');
      var truckLicense = this.get('adrLicenseTruck');
      var trailerLicense = this.get('adrLicenseTrailer');
      var haulierName = this.get('adrHaulierName');
      var driverName = this.get('adrDriverName');
      var areAllInventoriesEmpty = true;
      var truckInventories = this.get('sortedTruckInventories').reduce(function (result, truckInventory) {
        var truckInventoryData = {};
        truckInventoryData.compartmentId = truckInventory.get('truck_storage_id');
        truckInventoryData.product = truckInventory.get('material.MaterialNameShort');
        truckInventoryData.materialNameLong = truckInventory.get('material.MaterialNameLong');
        truckInventoryData.adrCode = truckInventory.get('materialAdr.materialAdrCode');
        truckInventoryData.currentQty = truckInventory.get('currentQty');
        truckInventoryData.currentQty > 0 ? areAllInventoriesEmpty = false : null;
        result.push(truckInventoryData);
        return result;
      }, []);
      var reportData = {
        truckId: truckId,
        truckLicense: truckLicense,
        trailerLicense: trailerLicense,
        loadSiteAddress: loadSiteAddress,
        haulierName: haulierName,
        driverName: driverName,
        truckInventories: truckInventories,
        areAllInventoriesEmpty: areAllInventoriesEmpty
      };
      return this.get('jsreport').postRequest(reportTemplateName, reportFileName, reportData).catch(function () {
        _this.get('toast').error('Cannot generate an ADR. Check your internet connection.');
      });
    },
    actions: {
      cancel: function cancel() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});